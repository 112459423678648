import './contact.scss';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

import Instagram from '../../assets/images/instagram.svg';
import Telegram from '../../assets/images/telegram.svg';
import Facebook from '../../assets/images/facebook.svg';
import Youtube from '../../assets/images/youtube.svg';
import Email from '../../assets/images/email.svg';
import { Link } from 'react-router-dom';

function SendMessage({ lang }) {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		const { name, message, email } = e.target.elements;

		emailjs
			.sendForm(
				'service_vtxjtgi',
				'template_aewt8yl',
				form.current,
				'CN3yvUP2KBJjPIbK-',
			)
			.then(
				(result) => {
					console.log(result.text);
					name.value = null;
					message.value = null;
					email.value = null;
				},
				(error) => {
					console.log(error.text);
				},
			);
	};

	return (
		<section className='send-message'>
			<div className='container'>
				<h2 className='heading' style={{ textAlign: 'center' }}>
					Contact us
				</h2>
				<p className='location'>
					<Link to='/'>Home</Link> / <Link to='/tours'>Contact</Link>
				</p>

				<div className='send-message__box'>
					<form className='form' ref={form} onSubmit={sendEmail}>
						<h2 className='send-message__heading'>Send Message</h2>
						<input
							className='send-message__form__input'
							type='text'
							name='name'
							placeholder='Name'
							required
						/>
						<input
							className='send-message__form__input'
							type='email'
							name='email'
							placeholder='Email'
							required
						/>
						<textarea
							className='send-message__form__input'
							type='text'
							name='message'
							placeholder='Message'
							required
						/>
						<button className='send-message__form__button'>
							Send message
						</button>
					</form>

					<ul className='send-message__list'>
						<li className='send-message__item'>
							<h3 className='send-message__item__title'>
								Yunusabad District, Tashkent,
								Uzbekistan
							</h3>
						</li>
						<li className='send-message__item'>
							<svg
								width='24'
								height='24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M5.737 4.044C4.829 5.035 3.985 6.274 4 7.952c.016 1.74.962 4.23 4.39 7.658s5.918 4.374 7.658 4.39c1.679.015 2.917-.83 3.908-1.737l-3.141-3.14-1.25 1.25a1 1 0 0 1-1.13.198c-1.645-.768-3.097-1.718-4.194-2.805-1.612-1.595-2.476-3.32-2.84-4.265a1 1 0 0 1 .227-1.066l1.25-1.25-3.141-3.14zM4.35 2.597c.755-.81 1.996-.771 2.734-.033l3.262 3.262c.75.75.75 1.968 0 2.718l-.824.825a10.88 10.88 0 0 0 2.125 2.976c.767.76 1.791 1.478 3.009 2.107l.799-.799c.75-.75 1.968-.75 2.718 0l3.262 3.262c.738.738.777 1.98-.033 2.734-1.128 1.052-2.872 2.374-5.373 2.35-2.488-.022-5.451-1.371-9.054-4.975C3.372 13.421 2.023 10.458 2 7.97 1.977 5.47 3.3 3.725 4.351 2.597z'
									fill='#000'
								/>
							</svg>
							<a
								className='send-message__item__link'
								href='tel:+998979101387'>
								+998 97 910 13 87
							</a>
						</li>
						<li className='send-message__item'>
							<svg
								width='24'
								height='24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M5.737 4.044C4.829 5.035 3.985 6.274 4 7.952c.016 1.74.962 4.23 4.39 7.658s5.918 4.374 7.658 4.39c1.679.015 2.917-.83 3.908-1.737l-3.141-3.14-1.25 1.25a1 1 0 0 1-1.13.198c-1.645-.768-3.097-1.718-4.194-2.805-1.612-1.595-2.476-3.32-2.84-4.265a1 1 0 0 1 .227-1.066l1.25-1.25-3.141-3.14zM4.35 2.597c.755-.81 1.996-.771 2.734-.033l3.262 3.262c.75.75.75 1.968 0 2.718l-.824.825a10.88 10.88 0 0 0 2.125 2.976c.767.76 1.791 1.478 3.009 2.107l.799-.799c.75-.75 1.968-.75 2.718 0l3.262 3.262c.738.738.777 1.98-.033 2.734-1.128 1.052-2.872 2.374-5.373 2.35-2.488-.022-5.451-1.371-9.054-4.975C3.372 13.421 2.023 10.458 2 7.97 1.977 5.47 3.3 3.725 4.351 2.597z'
									fill='#000'
								/>
							</svg>
							<a
								className='send-message__item__link'
								href='tel:+998972853355'>
								+998 97 285 33 55
							</a>
						</li>
						<li className='send-message__item'>
							<svg
								width='24'
								height='24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M5.737 4.044C4.829 5.035 3.985 6.274 4 7.952c.016 1.74.962 4.23 4.39 7.658s5.918 4.374 7.658 4.39c1.679.015 2.917-.83 3.908-1.737l-3.141-3.14-1.25 1.25a1 1 0 0 1-1.13.198c-1.645-.768-3.097-1.718-4.194-2.805-1.612-1.595-2.476-3.32-2.84-4.265a1 1 0 0 1 .227-1.066l1.25-1.25-3.141-3.14zM4.35 2.597c.755-.81 1.996-.771 2.734-.033l3.262 3.262c.75.75.75 1.968 0 2.718l-.824.825a10.88 10.88 0 0 0 2.125 2.976c.767.76 1.791 1.478 3.009 2.107l.799-.799c.75-.75 1.968-.75 2.718 0l3.262 3.262c.738.738.777 1.98-.033 2.734-1.128 1.052-2.872 2.374-5.373 2.35-2.488-.022-5.451-1.371-9.054-4.975C3.372 13.421 2.023 10.458 2 7.97 1.977 5.47 3.3 3.725 4.351 2.597z'
									fill='#000'
								/>
							</svg>
							<a
								className='send-message__item__link'
								href='tel:+998972884400'>
								+998 97 288 44 00
							</a>
						</li>
						<li className='send-message__item'>
							<img src={Email} alt='Email icon' width={24} height={24} />
							<a
								className='send-message__item__link'
								href='mailto:info@avesto.com'>
								info@avesto.com
							</a>
						</li>

						<li className='send-message__item'>
							<div className='send-message__item__box'>
								<a
									className='send-message__item__box__link instagram'
									href='/'
									target='_blank'>
									<img
										src={Instagram}
										alt='Instagram icon'
										width={30}
										height={30}
									/>
								</a>
								<a
									className='send-message__item__box__link telegram'
									href='/'
									target='_blank'>
									<img
										src={Telegram}
										alt='Telegram icon'
										width={30}
										height={30}
									/>
								</a>
								<a
									className='send-message__item__box__link facebook'
									href='/'
									target='_blank'>
									<img
										src={Facebook}
										alt='Facebook icon'
										width={30}
										height={30}
									/>
								</a>
								<a
									className='send-message__item__box__link youtube'
									href='/'
									target='_blank'>
									<img
										src={Youtube}
										alt='Youtube icon'
										width={30}
										height={30}
									/>
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}

export default SendMessage;
