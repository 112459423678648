import './about.scss';
import HomeAbout from '../homeAbout/homeAbout';

function About({ lang }) {
	return (
		<>
			<HomeAbout />

			<section className='about'>
				<div className='container'>
					<p className='about__text'>
						Avesto trip is a travel agency, based in Samarkand, in
						the heart of Uzbekistan and the Silk Road. The guides,
						associated with Golden Trace Voyage, are perfectly
						French-speaking and know their country better than anyone.
						With a solid experience in the field, the Avicenne Travel
						Agency and its entire team brings you its know-how for the
						organization of trips in Uzbekistan and all of Central Asia.
						<br />
						<br />
						The Avesto agency specializes in accompanying
						individuals or small groups, by creating tailor-made tours on
						demand. Oxus takes care of booking hotels,
						internal transport and above all, allowing the discovery of
						Central Asia in the best conditions, with or without a guide,
						whether you have come for a 15-day hike or to discover the
						most beautiful monuments of Uzbekistan.
						<br />
						<br />
						Tell us about your project by requesting a detailed quote, or
						discover our examples of circuits in Uzbekistan and Central
						Asia
					</p>
				</div>
			</section>
		</>
	);
}

export default About;
