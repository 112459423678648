import './homeAbout.scss';
import { useNavigate } from 'react-router-dom';

function HomeAbout({ lang }) {
	const navigate = useNavigate();

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<>
			<section className='home-about'>
				<div className='container reveal'>
					<div className='home-about__box'>
						<div className='home-about__left'>
							<img
								src='https://basmalah.uz/thumb/2/Fvzpdy4jSS2SFuL77zOwvw/1920r1080/d/55327168_2.jpg'
								alt='image'
								width={400}
								height={400}
							/>
						</div>

						<div className='home-about__right'>
							<h2 className='home-about__right__heading'>About Us</h2>
							<p className='home-about__right__text'>
							Avesto trip is a travel agency based in
								Samarkand, in the heart of Uzbekistan and the Silk Road.
								With solid experience in the field, the Avicenne Travel
								Agency and its entire team bring you their know-how in
								organizing trips to Uzbekistan and Central Asia.
							</p>

							<ul className='home-about__right__list'>
								<li className='home-about__right__item'>
									<div className='item__left-box'>
										<img
											src='https://basmalah.uz/thumb/2/ke4oo4MFs5QqQvVrRLFkng/640r480/d/svgexport-8_2023-03-09t113220586.svg'
											alt='image'
											width={65}
											height={65}
										/>
									</div>
									<div className='item__right-box'>
										<h4 className='item__right__heading'>8+</h4>
										<p className='item__right__text'>
											Years of experience
										</p>
									</div>
								</li>
								<li className='home-about__right__item'>
									<div className='item__left-box'>
										<img
											src='https://basmalah.uz/thumb/2/gOb7pjd5KjdLrk6aS-5SnQ/640r480/d/svgexport-9_2023-03-09t113301954.svg'
											alt='image'
											width={65}
											height={65}
										/>
									</div>
									<div className='item__right-box'>
										<h4 className='item__right__heading'>50+</h4>
										<p className='item__right__text'>Best tours</p>
									</div>
								</li>
							</ul>

							<button
								className='home-about__right__btn'
								onClick={() => navigate('/about')}>
								Read More
							</button>
							
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeAbout;
